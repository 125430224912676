import { FormFields } from './types/form';

export const formDefaults: FormFields = {
  currentStep: 'date_and_time',
  date: null,
  time: null,
  cpr: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  otp: ['', '', '', '', '', ''],
  acceptTerms: false,
};
