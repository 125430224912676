import { format, isSameDay } from 'date-fns';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, SelectButton } from '../components/button';
import { AvailableDate } from '../lib/api';
import { FormFields } from '../types/form';

type Props = {
  dates: AvailableDate[];
};

const DEFAULT_LIMIT = 10;

export const StepDates = (props: Props) => {
  const { setValue, watch } = useFormContext<FormFields>();
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const selectedDate = watch('date') || '';
  const totalDates = Object.keys(props.dates).length;

  const handleSelectDate = (date: Date) => {
    setValue('date', date);
    setValue('time', null);
  };

  const showNextDates = () => {
    setLimit(limit + 5);
  };

  if (totalDates === 0)
    return <p className="text-center">Der er desværre ingen ledige datoer.</p>;

  return (
    <div>
      <h2 className="text-lg font-semibold text-center mb-4">Vælg en dato</h2>

      <div className="grid grid-cols-5 gap-x-2 mb-1">
        <div className="text-sm text-gray-400 text-center">Man</div>
        <div className="text-sm text-gray-400 text-center">Tirs</div>
        <div className="text-sm text-gray-400 text-center">Ons</div>
        <div className="text-sm text-gray-400 text-center">Tors</div>
        <div className="text-sm text-gray-400 text-center">Fre</div>
      </div>
      <div className="grid grid-cols-5 gap-x-2 gap-y-4">
        {props.dates
          .filter((_, i) => i < limit)
          .map((date, j) => {
            const isSelected = isSameDay(date.start, selectedDate);

            return (
              <div className="flex justify-center" key={j}>
                <SelectButton
                  disabled={!date.available}
                  isSelected={isSelected}
                  onClick={() => handleSelectDate(date.start)}
                >
                  {format(date.start, 'd MMM')}
                </SelectButton>
              </div>
            );
          })}
      </div>

      <div className="flex justify-center mt-6 mb-12">
        {totalDates > limit && (
          <Button
            className="bg-primary-300 px-5 text-sm"
            color="dark"
            onClick={showNextDates}
          >
            Vis flere datoer
          </Button>
        )}
      </div>
    </div>
  );
};
