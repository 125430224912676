type AlertProps = {
  children: React.ReactNode;
};

export const Alert = ({ children }: AlertProps) => {
  return (
    <div className="bg-red-50 p-3 mb-5 rounded-md mx-2">
      <div className="text-sm text-red-700 text-center">{children}</div>
    </div>
  );
};
