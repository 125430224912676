import {
  add,
  differenceInDays,
  formatISO,
  isSameDay,
  isWeekend,
  nextFriday,
  startOfWeek,
} from 'date-fns';
import useSWR from 'swr';
import { iso8601Date } from './utils';

const API_ROOT = import.meta.env.VITE_HEMI_API_URL;
const DEFAULT_PRODUCT_ID = 206603;

const fetcher = (url: string, options: RequestInit) =>
  fetch(url, options).then((res) => res.json());

type AvailableDateResult = {
  start: string;
  available: boolean;
};

export type AvailableDate = Omit<AvailableDateResult, 'start'> & {
  start: Date;
};

export const useGetAvailableDates = () => {
  const start = startOfWeek(new Date(), {
    weekStartsOn: 1, // Monday
  });
  const end = nextFriday(add(start, { weeks: 3 }));
  const url = `${API_ROOT}/v1/online-booking/available-dates?from=${iso8601Date(
    start
  )}&to=${iso8601Date(end)}&productId=${DEFAULT_PRODUCT_ID}`;

  const { data, isLoading, error } = useSWR<AvailableDateResult[]>(
    'times',
    () =>
      fetcher(url, {
        method: 'GET',
      }),
    {
      revalidateOnFocus: false,
    }
  );

  if (data && data.length > 0) {
    let dates: AvailableDate[] = [];
    const diffInDays = differenceInDays(end, start) + 1;

    // Loop through all days between start and end
    // If a day doesn't exist in the data from the api,
    // add it with available: false
    for (let i = 0; i <= diffInDays; i++) {
      const day = add(start, { days: i });
      const date = data.find((d) => isSameDay(d.start, day));

      if (date) {
        dates.push({
          start: day,
          available: date.available,
        });
      } else {
        dates.push({
          start: day,
          available: false,
        });
      }
    }

    // Remove weekends and sort
    dates = dates
      .filter((d) => !isWeekend(d.start))
      .sort((a, b) => a.start.getTime() - b.start.getTime());

    return {
      dates,
      isLoading,
      error,
    };
  }

  return {
    dates: [],
    isLoading,
    error,
  };
};

type AvailableTimeResult = {
  start: string;
  end: string;
  calendarId: number;
  employeeId: number;
};

export type AvailableTime = Omit<AvailableTimeResult, 'start' | 'end'> & {
  start: Date;
  end: Date;
};

export const useGetAvailableTimes = (date: Date | null) => {
  const isoDate = date ? iso8601Date(date) : '';
  const url = `${API_ROOT}/v1/online-booking/available-times?date=${isoDate}&productId=${DEFAULT_PRODUCT_ID}`;
  const { data, isLoading, error } = useSWR<AvailableTimeResult[]>(
    date ? `times-${isoDate}` : null,
    () =>
      fetcher(url, {
        method: 'GET',
      }),
    {
      revalidateOnFocus: false,
    }
  );

  if (data && data.length > 0) {
    const times: AvailableTime[] =
      data.map((d) => ({
        start: new Date(d.start),
        end: new Date(d.end),
        calendarId: d.calendarId,
        employeeId: d.employeeId,
      })) || [];

    return {
      times,
      isLoading,
      error,
    };
  }

  return {
    times: [],
    isLoading,
    error,
  };
};

export const sendOtp = async (phone: string) =>
  await fetch(`${API_ROOT}/v1/auth/otp`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ phone }),
  });

type SignupPayload = {
  cpr: string;
  otp: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export const signup = async (payload: SignupPayload) =>
  await fetch(`${API_ROOT}/v1/auth/signup-login`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });

type BookPayload = {
  calendarId: number;
  employeeId: number;
  cpr: string;
  start: string;
  end: string;
};

export const book = async (token: string, payload: BookPayload) =>
  await fetch(`${API_ROOT}/v1/bookings`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      start: formatISO(payload.start),
      end: formatISO(payload.end),
      productId: DEFAULT_PRODUCT_ID,
    }),
  });
