import * as Sentry from '@sentry/browser';
import { ErrorBoundary } from '@sentry/react';
import { setDefaultOptions } from 'date-fns';
import { da } from 'date-fns/locale';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app.tsx';
import { ErrorFallback } from './components/error-fallback.tsx';
import './style.css';

Sentry.init({
  enabled: import.meta.env.MODE === 'production',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
});

setDefaultOptions({ locale: da });

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ErrorBoundary fallback={ErrorFallback} showDialog>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);
