import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LargeButton } from '../components/button';
import { Input } from '../components/input';
import { Spinner } from '../components/spinner';
import { StepTitle } from '../components/text';
import { formDefaults } from '../form.helper';
import { sendOtp } from '../lib/api';
import { FormFields } from '../types/form';

export const StepPhone = () => {
  const { getValues, setValue, control, setError, clearErrors, trigger } =
    useFormContext<FormFields>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSendOtpClick = async () => {
    const valid = await trigger('phone');
    if (valid) {
      setIsLoading(true);
      clearErrors();

      const phone = getValues('phone');
      const response = await sendOtp(phone);
      if (response.ok) {
        setIsLoading(false);
        setValue('otp', [...formDefaults.otp]);
        setValue('currentStep', 'personal');
      } else {
        setIsLoading(false);
        setError('phone', {
          type: 'manual',
          message:
            'Vi kunne ikke sende koden. Bekræft dit mobilnummer og prøv igen.',
        });
      }
    }
  };

  return (
    <div>
      <StepTitle>Indtast dit mobilnummer</StepTitle>

      <div className="mt-4">
        <Controller
          name="phone"
          control={control}
          rules={{
            required:
              'Indtast venligst dit mobilnummer. Du modtager en kode på SMS, som du skal bruge til at bekræfte din booking.',
            minLength: {
              value: 8,
              message: 'Mobilnummeret skal være mindst 8 cifre.',
            },
            maxLength: {
              value: 8,
              message: 'Mobilnummeret skal være højst 8 cifre.',
            },
            pattern: {
              value: /^[0-9]*$/,
              message: 'Mobilnummeret må kun indeholde tal.',
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              label="Mobilnummer"
              error={fieldState.error?.message}
              helper={{
                text: 'Du modtager en kode på SMS, som du skal bruge til at bekræfte din booking.',
                mode: 'warning',
              }}
              inputProps={{
                ...field,
                type: 'tel',
              }}
            />
          )}
        />
      </div>

      <div className="text-center mt-8">
        <LargeButton
          className={isLoading ? 'pointer-events-none' : ''}
          onClick={handleSendOtpClick}
        >
          {isLoading ? <Spinner color="light" size="sm" /> : 'Send kode'}
        </LargeButton>
      </div>
    </div>
  );
};
