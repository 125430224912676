import { Spinner } from './components/spinner';
import { Form } from './form';
import { useGetAvailableDates } from './lib/api';

function App() {
  const { dates, isLoading, error } = useGetAvailableDates();

  if (error) return <div>Error: {error.message}</div>;
  if (isLoading) {
    return (
      <div className="flex justify-center my-20">
        <Spinner size="lg" color="dark" />
      </div>
    );
  }

  return <Form dates={dates} />;
}

export default App;
