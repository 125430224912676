import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { Fragment } from 'react/jsx-runtime';
import { StepTitle } from '../components/text';
import { FormFields } from '../types/form';

export const StepCompleted = () => {
  const { getValues } = useFormContext<FormFields>();
  const time = getValues('time');

  return (
    <div className="flex flex-col items-center">
      <div className="bg-primary-800 h-16 w-full flex justify-center rounded-t-lg">
        <img
          src="https://assets-global.website-files.com/651158f6db112b314a519bf5/6633613a67df203b2847ac7f_Maren-round-p-500.webp"
          alt=""
          className="h-28 w-28 rounded-full transform -translate-y-6 border-4 border-white"
        />
      </div>
      <div className="bg-white shadow rounded-b-lg pt-10 pb-5 px-5 flex flex-col gap-y-4 items-center">
        <StepTitle>
          <div className="flex flex-row gap-x-2 items-center -translate-x-3">
            <CheckIcon />
            Booking bekræftet
          </div>
        </StepTitle>

        <p>
          Du har booket en{' '}
          <strong className="font-semibold">*Hemi Grundforløb*</strong>{' '}
          konsultation.
        </p>

        <p className="font-semibold">
          {time?.start && (
            <Fragment>
              Dato: {format(time.start, "d MMM yyyy 'kl' HH:mm")}
              <br />
            </Fragment>
          )}
          Sted: Online videokonsultation
        </p>
        <p className="text-sm text-center">
          Du modtager snart en bekræftelse for din booking på email.
          Bekræftelsen indeholder også et link til din videokonsultation.
        </p>
      </div>
    </div>
  );
};

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="currentColor"
    className="w-6 h-6 text-green-700"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    />
  </svg>
);
