import { useFormContext } from 'react-hook-form';
import { FormFields } from '../types/form';

type InputProps = {
  label: string;
  error: string | undefined;
  helper?: {
    text: string;
    mode: 'info' | 'warning';
  };
  inputProps: React.ComponentPropsWithoutRef<'input'>;
};

export const Input = (props: InputProps) => {
  return (
    <div>
      <label
        htmlFor={props.inputProps.name}
        className="block text-sm leading-6 text-gray-900 px-1"
      >
        {props.label}
      </label>

      <input
        className={`block w-full rounded-full mt-0.5 text-sm px-3 border border-gray-400 py-1.5 shadow-sm ${
          props.error && 'outline-red-500 border-red-500'
        }`}
        id={props.inputProps.name}
        {...props.inputProps}
      />

      {props.error && (
        <p className="mt-1 px-1 text-xs text-red-600">{props.error}</p>
      )}

      {props.helper && !props.error && (
        <p
          className={`mt-1.5 px-1 text-xs ${
            props.helper.mode === 'info' ? 'text-gray-500' : 'font-medium'
          }`}
        >
          {props.helper.text}
        </p>
      )}
    </div>
  );
};

type OtpInputProps = {
  otp: string[];
  index: number;
};

export const OtpInput = ({ otp, index }: OtpInputProps) => {
  const { setValue } = useFormContext<FormFields>();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = [...otp];
    newValue[index] = e.target.value;
    setValue('otp', newValue);

    // Move to next input
    if (e.target.value !== '') {
      const nextField = document.getElementById(
        `otp-${index + 1}`
      ) as HTMLInputElement;
      if (nextField) {
        nextField.focus();
        nextField.setSelectionRange(0, nextField.value.length);
      }
    }
  };

  return (
    <input
      id={`otp-${index}`}
      autoFocus={index === 0}
      className="w-full h-10 text-center focus:outline-none focus:bg-green-100"
      value={otp[index]}
      maxLength={1}
      onChange={onChange}
    />
  );
};

export const OutInputWrapper = ({
  error,
  children,
}: {
  error: boolean;
  children: React.ReactElement[];
}) => (
  <div
    className={`

  grid grid-cols-3 overflow-hidden gap-px border bg-gray-400 rounded-lg ${
    error ? 'border-red-500' : 'border-gray-400'
  }
  `}
  >
    {children}
  </div>
);
