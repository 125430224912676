import { Fragment, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Alert } from '../components/alert';
import { LargeButton } from '../components/button';
import { Checkbox } from '../components/checkbox';
import { Input, OtpInput, OutInputWrapper } from '../components/input';
import { Spinner } from '../components/spinner';
import { StepDescription, StepTitle } from '../components/text';
import { book, signup } from '../lib/api';
import { FormFields } from '../types/form';

const TERMS_URL = 'https://www.hemihealth.com/dk/terms-of-use';

export const StepPersonal = () => {
  const {
    watch,
    getValues,
    setValue,
    control,
    setError,
    clearErrors,
    formState,
    trigger,
  } = useFormContext<FormFields>();
  const { handleSignupError, handleBookError } = useHandleApiError();
  const { getToken } = useGetToken();
  const [isLoading, setIsLoading] = useState(false);
  const otp = watch('otp');

  const handleBackToOtpClick = async () => {
    setValue('currentStep', 'phone');
  };

  const handleSubmit = async () => {
    clearErrors();

    if (otp.some((x) => x === '')) {
      setError('otp', {
        type: 'manual',
        message: 'Indtast venligst koden du har modtaget på SMS.',
      });
      return;
    }

    const valid = await trigger([
      'cpr',
      'firstName',
      'lastName',
      'email',
      'phone',
      'acceptTerms',
    ]);
    if (!valid) return;

    setIsLoading(true);

    const signupResponse = await signup({
      cpr: getValues('cpr'),
      otp: otp.join(''),
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      email: getValues('email'),
      phone: getValues('phone'),
    });

    if (!signupResponse.ok) {
      await handleSignupError(signupResponse);
      setIsLoading(false);
      return;
    }

    const token = await getToken(signupResponse);
    if (!token) {
      setIsLoading(false);
      return;
    }

    const time = getValues('time');
    const bookResponse = await book(token, {
      cpr: getValues('cpr'),
      calendarId: time?.calendarId as number,
      employeeId: time?.employeeId as number,
      start: time?.start?.toISOString() as string,
      end: time?.end?.toISOString() as string,
    });

    if (!bookResponse.ok) {
      await handleBookError(bookResponse);
      setIsLoading(false);
      return;
    }

    setValue('currentStep', 'completed');
  };

  return (
    <div>
      <StepTitle>Indtast SMS kode</StepTitle>
      <StepDescription>
        Hvis du ikke har modtaget koden efter 30 sekunder eller hvis du har
        tastet et forkert mobilnummer ({getValues('phone')}), så{' '}
        <button
          className="underline hover:no-underline"
          onClick={handleBackToOtpClick}
          type="button"
        >
          klik her for at prøve igen
        </button>
        .
      </StepDescription>

      <div className="mb-14">
        <div className="flex flex-row items-center gap-3 mb-3">
          <OutInputWrapper error={!!formState.errors.otp}>
            <OtpInput otp={otp} index={0} />
            <OtpInput otp={otp} index={1} />
            <OtpInput otp={otp} index={2} />
          </OutInputWrapper>
          <div>&bull;</div>
          <OutInputWrapper error={!!formState.errors.otp}>
            <OtpInput otp={otp} index={3} />
            <OtpInput otp={otp} index={4} />
            <OtpInput otp={otp} index={5} />
          </OutInputWrapper>
        </div>
        {formState.errors.otp && (
          <p className="text-center text-sm text-red-500">
            {formState.errors.otp.message}
          </p>
        )}
      </div>

      <StepTitle>Indtast dine kontaktoplysninger</StepTitle>
      <StepDescription>
        Dine oplysninger bliver behandlet fortroligt og opbevares sikkert.
      </StepDescription>

      <div className="flex flex-col gap-3">
        <Controller
          name="cpr"
          control={control}
          rules={{
            required:
              'Indtast venligst dit CPR-nummer. 10 cifre uden bindestreg.',
            minLength: {
              value: 10,
              message: 'CPR-nummeret skal være 10 cifre uden bindestreg.',
            },
            maxLength: {
              value: 10,
              message: 'CPR-nummeret skal være 10 cifre uden bindestreg.',
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              label="CPR-nummer"
              error={fieldState.error?.message}
              inputProps={{
                ...field,
              }}
            />
          )}
        />

        <div className="grid md:grid-cols-2 gap-3">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: 'Indtast venligst dit fornavn.' }}
            render={({ field, fieldState }) => (
              <Input
                label="Fornavn"
                error={fieldState.error?.message}
                inputProps={field}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: 'Indtast venligst dit efternavn.' }}
            render={({ field, fieldState }) => (
              <Input
                label="Efternavn"
                error={fieldState.error?.message}
                inputProps={field}
              />
            )}
          />
        </div>

        <Controller
          name="email"
          control={control}
          rules={{
            required: 'Indtast venligst din email adresse.',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: 'Indtast venligst en gyldig email adresse.',
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              label="Email adresse"
              error={fieldState.error?.message}
              inputProps={{
                ...field,
                type: 'email',
              }}
            />
          )}
        />

        <div className="mt-4">
          <Controller
            name="acceptTerms"
            control={control}
            rules={{
              required: 'Du skal acceptere betingelserne for at fortsætte.',
            }}
            render={({ field, fieldState }) => (
              <Checkbox
                label={
                  <Fragment>
                    Jeg har læst og accepterer{' '}
                    <a href={TERMS_URL} target="_blank" tabIndex={-1}>
                      betingelserne
                    </a>
                    .
                  </Fragment>
                }
                inputProps={field}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
      </div>

      <div className="text-center mt-8">
        {formState.errors.root && (
          <div className="mb-5">
            <Alert>
              <p>{formState.errors.root.message}</p>
            </Alert>
          </div>
        )}

        <LargeButton
          className={isLoading ? 'pointer-events-none' : ''}
          onClick={handleSubmit}
        >
          {isLoading ? <Spinner color="light" size="sm" /> : 'Bekræft booking'}
        </LargeButton>
      </div>
    </div>
  );
};

const useHandleApiError = () => {
  const { setError } = useFormContext<FormFields>();
  const rootError = {
    type: 'manual',
    message: 'Der opstod desværre en fejl. Prøv igen senere.',
  };

  const handleSignupError = async (response: Response) => {
    try {
      const json = await response.json();
      if (json.field) {
        const message =
          json.field === 'cpr'
            ? 'Det indtastede CPR nummer er ugyldigt.'
            : json.field === 'otp'
            ? 'Den indtastede SMS kode er forkert.'
            : json.message;

        setError(json.field, {
          type: 'manual',
          message,
        });
        return;
      }

      setError('root', rootError);
    } catch (error) {
      setError('root', rootError);
    }
  };

  const handleBookError = async (response: Response) => {
    try {
      const json = await response.json();
      if (json.field) {
        setError(json.field, { type: 'manual', message: json.message });
        return;
      }
      setError('root', rootError);
    } catch (error) {
      setError('root', rootError);
    }
  };

  return { handleSignupError, handleBookError };
};

const useGetToken = () => {
  const { setError } = useFormContext<FormFields>();

  const getToken = async (response: Response) => {
    try {
      const json = await response.json();
      return json.token;
    } catch (error) {
      setError('root', {
        type: 'manual',
        message: 'Der er sket en fejl. Prøv igen senere.',
      });
      console.log('No token');
      return null;
    }
  };

  return { getToken };
};
