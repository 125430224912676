export const ErrorFallback = () => {
  return (
    <Container>
      <div className="rounded-md bg-yellow-50 p-4 text-center">
        <h3 className="text-sm font-bold mb-2">Der opstod en fejl</h3>
        <div className="flex flex-col gap-y-2 text-sm">
          <p>Der er desværre opstået en fejl under bookingen.</p>
          <p>
            Kontakte os gerne på tlf:{' '}
            <a className="underline" href="tel:+4542542200">
              42 54 22 00
            </a>
          </p>
          <p>Så hjælper vi dig med at booke en tid.</p>
        </div>
      </div>
    </Container>
  );
};

const Container = ({ children }: { children: React.ReactNode }) => (
  <div className="pt-10 px-2 pb-10 max-w-lg mx-auto">{children}</div>
);
