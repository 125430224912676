import { FormProvider, useForm } from 'react-hook-form';
import { Fragment } from 'react/jsx-runtime';
import { formDefaults } from './form.helper';
import { AvailableDate } from './lib/api';
import { StepCompleted } from './steps/step-completed';
import { StepDates } from './steps/step-dates';
import { StepPersonal } from './steps/step-personal';
import { StepPhone } from './steps/step-phone';
import { StepTimes } from './steps/step-times';
import { FormFields } from './types/form';

type Props = {
  dates: AvailableDate[];
};

export const Form = ({ dates }: Props) => {
  const form = useForm<FormFields>({
    defaultValues: {
      ...formDefaults,
    },
    mode: 'onBlur',
  });
  const { watch } = form;
  const { currentStep } = watch();

  return (
    <FormProvider {...form}>
      <Container>
        {/* <pre className="text-[11px] h-24 border overflow-auto my-3">
            {JSON.stringify(dates, null, 2)}
          </pre> */}
        {/* <pre className="text-[11px] h-64 border overflow-auto">
            {JSON.stringify(watch(), null, 2)}
          </pre> */}

        {currentStep === 'date_and_time' && (
          <Fragment>
            <StepDates dates={dates} />
            <StepTimes />
          </Fragment>
        )}
        {currentStep === 'phone' && <StepPhone />}
        {currentStep === 'personal' && <StepPersonal />}
        {currentStep === 'completed' && <StepCompleted />}
      </Container>
    </FormProvider>
  );
};

const Container = ({ children }: { children: React.ReactNode }) => (
  <div className="pt-10 px-2 pb-10 max-w-lg mx-auto">{children}</div>
);
