import { format, isSameDay, isSameHour, isSameMinute } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { LargeButton, SelectButton } from '../components/button';
import { Spinner } from '../components/spinner';
import { StepTitle } from '../components/text';
import { useGetAvailableTimes } from '../lib/api';
import { FormFields } from '../types/form';

export const StepTimes = () => {
  const { watch, setValue, trigger } = useFormContext<FormFields>();
  const date = watch('date');
  const { times, isLoading, error } = useGetAvailableTimes(date);
  const selectedTime = watch('time');

  const handleContinueClick = async () => {
    const valid = await trigger(['date', 'time']);
    if (valid) {
      setValue('currentStep', 'phone');
    }
  };

  if (error) return <div>Error: {error.message}</div>;
  if (isLoading) {
    return (
      <div className="flex justify-center my-20">
        <Spinner size="sm" color="dark" />
      </div>
    );
  }
  if (!date) return null;
  if (times.length === 0)
    return <p>Der er desværre ingen ledige tider {format(date, 'dd-MMM')}</p>;

  return (
    <div>
      <StepTitle>Vælg et tidspunkt</StepTitle>

      <div className="grid grid-cols-5 gap-x-2 gap-y-4 mt-4">
        {times.map((time, j) => {
          const isSelected =
            selectedTime?.start != null &&
            isSameDay(time.start, selectedTime.start) &&
            isSameHour(time.start, selectedTime.start) &&
            isSameMinute(time.start, selectedTime.start);

          return (
            <div className="flex justify-center" key={j}>
              <SelectButton
                isSelected={isSelected}
                onClick={() => setValue('time', time)}
              >
                {format(time.start, 'HH:mm')}
              </SelectButton>
            </div>
          );
        })}
      </div>

      <div className="text-center mt-10">
        <LargeButton onClick={handleContinueClick}>Fortsæt</LargeButton>
      </div>
    </div>
  );
};
