import { cx } from '../lib/utils';

type ButtonProps = React.ComponentPropsWithoutRef<'button'>;

export const Button = (props: ButtonProps) => (
  <button
    type="button"
    {...props}
    disabled={props.disabled}
    className={cx(
      'rounded-full px-2 py-1',
      'bg-primary-600 hover:bg-primary-700 text-white',
      props.disabled
        ? '!bg-gray-100 !text-gray-400 !cursor-not-allowed !shadow-none'
        : '',
      props.className ? props.className : ''
    )}
  >
    {props.children}
  </button>
);

export const LargeButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      className="!px-6 !py-2 !font-semibold focus:!ring-4 focus:!ring-offset-0 focus:!ring-primary-100"
    />
  );
};

type SelectButtonProps = ButtonProps & {
  isSelected: boolean;
};

export const SelectButton = (props: SelectButtonProps) => {
  const { isSelected, ...rest } = props;

  return (
    <button
      {...rest}
      color="light"
      className={cx(
        'rounded-full h-9 w-full max-w-20 text-sm',
        'shadow-sm border hover:bg-primary-50 hover:shadow-none text-primary-800',
        isSelected
          ? 'ring-2 ring-offset-2 ring-primary-100 bg-primary-50'
          : 'bg-white',
        props.disabled
          ? ' !bg-gray-100 !text-gray-400 !cursor-not-allowed !shadow-none'
          : ''
      )}
    />
  );
};
